@import "https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700&family=Inconsolata&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

textarea, input[type="text"], input[type="email"], input[type="tel"] {
  -webkit-appearance: none;
}

button, input[type="submit"] {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

input, button {
  border: 0;
  margin: 0;
  padding: 0;
}

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: #fff;
  width: 100%;
  font-family: Commissioner, sans-serif;
  position: relative;
  overflow-x: hidden;
}

.myBg {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.myBg .bgHeader {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
  background: radial-gradient(76.44% 76.44% at 29.58% 31.61%, #00b2ff 0%, #794aff 68.23%, #44259a 100%);
  width: 100%;
  height: 100vh;
}

@media only screen and (width <= 998px) {
  .myBg .bgHeader {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    height: 400px;
  }
}

.myBg .bgPotential {
  background-color: #fff;
  width: 100%;
  height: 590px;
}

.myBg .bgMyVideo {
  clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 100%);
  background: #f9faff;
  width: 100%;
  height: 120vh;
}

@media only screen and (width <= 998px) {
  .myBg .bgMyVideo {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    height: 700px;
  }
}

.myBg .bgUse {
  background: #fff;
  width: 100%;
  height: 115vh;
}

.myBg .bgVersion {
  background: #f9faff;
  width: 100%;
  height: 120vh;
}

.myBg .lineBg {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 998px) {
  .myBg .lineBg {
    display: none;
  }
}

.myBg .lineBg .line {
  opacity: .3;
  background-color: #d9d9d9;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
}

.myBg .lineBg .line:first-child {
  left: 200px;
}

.myBg .lineBg .line:nth-child(2) {
  left: calc(20% + 120px);
}

.myBg .lineBg .line:nth-child(3) {
  left: calc(40% + 40px);
}

.myBg .lineBg .line:nth-child(4) {
  left: calc(60% - 40px);
}

.myBg .lineBg .line:nth-child(5) {
  left: calc(80% - 120px);
}

.myBg .lineBg .line:nth-child(6) {
  left: calc(100% - 200px);
}

header {
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  position: relative;
}

@media only screen and (width <= 998px) {
  header {
    min-height: 400px;
    padding-top: 40px;
  }
}

.navMenu {
  width: 100%;
  padding: 35px 0 10px 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 998px) {
  .navMenu {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
}

.navMenu .burger {
  display: none;
}

@media only screen and (width <= 998px) {
  .navMenu .burger {
    display: block;
  }
}

.navMenu img {
  padding: 5px 50px 5px 100px;
}

@media only screen and (width <= 998px) {
  .navMenu img {
    padding: 10px;
  }
}

.navMenu nav {
  display: flex;
}

@media only screen and (width <= 998px) {
  .navMenu nav {
    display: none;
  }
}

.navMenu nav a {
  color: #fff;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.mainBlock {
  width: 100%;
  display: flex;
}

.mainBlock .mainBlockTitle {
  color: #fff;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding-top: 250px;
  padding-left: 270px;
  display: flex;
}

@media only screen and (width <= 1500px) {
  .mainBlock .mainBlockTitle {
    padding-left: 20px;
  }
}

@media only screen and (width <= 998px) {
  .mainBlock .mainBlockTitle {
    padding-top: 100px;
    padding-left: 20px;
  }
}

.mainBlock .mainBlockTitle .headerTitle {
  margin-bottom: 30px;
  font-size: 56px;
  font-weight: 700;
}

@media only screen and (width <= 998px) {
  .mainBlock .mainBlockTitle .headerTitle {
    font-size: 18px;
  }
}

.mainBlock .mainBlockTitle .headerDesc {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 300;
  line-height: 33px;
}

@media only screen and (width <= 998px) {
  .mainBlock .mainBlockTitle .headerDesc {
    font-size: 12px;
    line-height: 120%;
  }
}

.mainBlock .mainBlockTitle .headerReestr {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 300;
  line-height: 33px;
}

.mainBlock .mainBlockTitle .headerButton {
  color: #fff;
  background-color: #4c63e6;
  border: 1px solid #fff;
  border-radius: 18px;
  padding: 20px 40px;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

@media only screen and (width <= 998px) {
  .mainBlock .mainBlockTitle .headerButton {
    padding: 10px 15px;
    font-size: 14px;
  }
}

.mainBlock .mainBlockImage {
  justify-content: flex-end;
  width: 40%;
  padding-top: 30px;
  display: flex;
}

@media only screen and (width <= 1500px) {
  .mainBlock .mainBlockImage {
    padding-top: 100px;
    position: relative;
    overflow-x: hidden;
  }
}

@media only screen and (width <= 998px) {
  .mainBlock .mainBlockImage {
    display: block;
  }
}

.mainBlock .mainBlockImage img {
  max-width: 100%;
}

@media only screen and (width <= 1500px) {
  .mainBlock .mainBlockImage img {
    width: 400px;
    height: 300px;
  }
}

@media only screen and (width <= 998px) {
  .mainBlock .mainBlockImage img {
    width: auto;
    max-width: none;
  }
}

.potential {
  justify-content: center;
  width: 100%;
  display: flex;
}

.potentialWrap {
  width: 1400px;
}

@media only screen and (width <= 1500px) {
  .potentialWrap {
    width: 100%;
    padding: 20px;
  }
}

.potentialWrap .potentialTitle {
  margin: 25px 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}

@media only screen and (width <= 998px) {
  .potentialWrap .potentialTitle {
    margin: 10px 0;
    font-size: 18px;
    line-height: 100%;
  }
}

.potentialWrap .potentialDesc {
  color: #828282;
  margin-bottom: 70px;
  font-size: 22px;
  line-height: 150%;
}

@media only screen and (width <= 998px) {
  .potentialWrap .potentialDesc {
    margin-bottom: 30px;
    font-size: 12px;
  }
}

.potentialItems {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  display: flex;
}

.potentialItems .item {
  align-items: center;
  width: 43%;
  margin-bottom: 50px;
  display: flex;
}

@media only screen and (width <= 800px) {
  .potentialItems .item {
    width: 100%;
    margin-bottom: 20px;
  }
}

.potentialItems .item img {
  margin-right: 25px;
}

@media only screen and (width <= 800px) {
  .potentialItems .item img {
    width: 30px;
    margin-right: 15px;
  }
}

.potentialItems .itemName {
  color: #000;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}

@media only screen and (width <= 998px) {
  .potentialItems .itemName {
    font-size: 14px;
  }
}

@media only screen and (width <= 998px) and (width <= 800px) {
  .potentialItems .itemName {
    line-height: 120%;
  }
}

.myVideo {
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 150px;
  display: flex;
}

@media only screen and (width <= 998px) {
  .myVideo {
    min-height: 300px;
    padding-top: 50px;
  }
}

.myVideoWrap {
  width: 1120px;
}

@media only screen and (width <= 1200px) {
  .myVideoWrap {
    width: 100%;
    padding: 0;
  }

  iframe {
    width: 100%;
    height: 300px;
  }
}

.use {
  justify-content: center;
  width: 100%;
  min-height: 80vh;
  padding-bottom: 40px;
  display: flex;
}

.useWrap {
  width: 1400px;
}

@media only screen and (width <= 1500px) {
  .useWrap {
    width: 998px;
  }
}

.useTitle {
  color: #000;
  width: 100%;
  margin: 80px 0 70px;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}

@media only screen and (width <= 1500px) {
  .useTitle {
    padding: 0 20px;
  }
}

@media only screen and (width <= 700px) {
  .useTitle {
    margin: 40px 0 15px;
    font-size: 22px;
  }
}

.useBlock {
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
  display: flex;
}

@media only screen and (width <= 1500px) {
  .useBlock {
    width: 100%;
  }
}

.useItem {
  color: #000;
  border: 1px solid #d9d9d9;
  height: 315px;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
  position: relative;
}

@media only screen and (width <= 998px) {
  .useItem {
    height: 220px;
    font-size: 14px;
    line-height: 15px;
  }
}

@media only screen and (width <= 500px) {
  .useItem {
    height: 150px;
    font-size: 10px;
  }
}

.useItem p {
  width: 100%;
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (width <= 700px) {
  .useItem p {
    padding: 10px;
  }
}

.useItem:first-child {
  background-image: url("block1.7a7f6cd3.png");
  background-size: cover;
  width: 440px;
}

@media only screen and (width <= 1500px) {
  .useItem:first-child {
    order: 1;
    width: 60%;
  }
}

.useItem:nth-child(2) {
  background-image: url("image 5.2c0acc31.png");
  background-size: cover;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-right: none;
  width: 320px;
}

@media only screen and (width <= 1500px) {
  .useItem:nth-child(2) {
    border: 1px solid #d9d9d9;
    border-left: none;
    order: 1;
    width: 40%;
  }
}

.useItem:nth-child(3) {
  color: #fff;
  background-image: url("block2.27264f1c.png");
  background-size: cover;
  width: 440px;
}

.useItem:nth-child(3) p {
  background: radial-gradient(156.35% 164.31% at 2.15% -7.72%, #00b2ff 0%, #794aff 68.23%, #44259a 100%);
}

@media only screen and (width <= 1500px) {
  .useItem:nth-child(3) {
    border: 1px solid #d9d9d9;
    border-top: none;
    border-left: none;
    order: 3;
    width: 55%;
  }
}

.useItem:nth-child(4) {
  background-image: url("block3.8b1a11b4.png");
  background-size: cover;
  border-top: none;
  width: 300px;
}

@media only screen and (width <= 1500px) {
  .useItem:nth-child(4) {
    border: 1px solid #d9d9d9;
    border-top: none;
    order: 2;
    width: 45%;
  }
}

.useItem:nth-child(5) {
  color: #fff;
  background: radial-gradient(124.24% 120.35% at 0 -2.56%, #00b2ff 0%, #794aff 68.23%, #44259a 100%);
  border: none;
  border-bottom: 1px solid #d9d9d9;
  width: 300px;
}

@media only screen and (width <= 1500px) {
  .useItem:nth-child(5) {
    border: 1px solid #d9d9d9;
    border-top: none;
    order: 3;
    width: 30%;
  }
}

.useItem:nth-child(6) {
  color: #fff;
  background-image: url("block4.e4f21db3.png");
  background-size: cover;
  border-top: none;
  width: 600px;
}

@media only screen and (width <= 1500px) {
  .useItem:nth-child(6) {
    border: 1px solid #d9d9d9;
    border-top: none;
    border-left: none;
    order: 3;
    width: 70%;
  }
}

.useSliderItem {
  padding: 10px;
}

.version {
  justify-content: center;
  min-height: 80vh;
  display: flex;
}

.versionWrap {
  flex-direction: column;
  width: 1200px;
  padding-bottom: 120px;
  display: flex;
}

@media only screen and (width <= 998px) {
  .versionWrap {
    padding-bottom: 40px;
  }
}

.versionTitle {
  text-align: center;
  width: 100%;
  margin: 170px 0 70px;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}

@media only screen and (width <= 700px) {
  .versionTitle {
    margin: 50px 0 30px;
    font-size: 24px;
  }
}

.versionItems {
  justify-content: center;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 998px) {
  .versionItems {
    flex-wrap: wrap;
  }
}

.versionItem {
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  width: 420px;
  height: 520px;
  margin: 30px;
  padding: 40px;
  display: flex;
  box-shadow: 0 0 40px #0000001a;
}

@media only screen and (width <= 998px) {
  .versionItem {
    width: 100%;
  }
}

@media only screen and (width <= 700px) {
  .versionItem {
    height: 370px;
    padding: 15px;
  }
}

.versionItem:nth-child(2), .versionItem:nth-child(3) {
  border: 2px solid #4c63e6;
}

.versionItemTitle {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 600;
  line-height: 100%;
}

@media only screen and (width <= 700px) {
  .versionItemTitle {
    font-size: 18px;
  }
}

.versionItemDesc {
  color: #000;
  flex-grow: 2;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}

@media only screen and (width <= 700px) {
  .versionItemDesc {
    font-size: 16px;
  }
}

.versionItemDesc p {
  padding: 4px 0;
}

.versionItemDesc .buyVersion {
  color: #4c63e6;
}

.versionItemButton {
  margin-top: 20px;
}

.versionItemButton p {
  color: #9d9d9d;
  padding: 10px;
  font-size: 14px;
}

.versionItemButton button {
  box-sizing: border-box;
  background-color: #0000;
  border: 1px solid #000;
  border-radius: 18px;
  padding: 13px 40px;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

@media only screen and (width <= 700px) {
  .versionItemButton button {
    font-size: 18px;
  }
}

.versionItemButton .buttonBuy {
  color: #fff;
  background: linear-gradient(104.44deg, #4c63e6 -6.58%, #a24ce6 113.74%);
  border: 1px solid #4c63e6;
}

.contForm {
  justify-content: space-between;
  display: flex;
}

@media only screen and (width <= 1100px) {
  .contForm {
    flex-wrap: wrap;
  }
}

.contForm button {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 18px;
  padding: 20px 36px;
  font-size: 24px;
  font-weight: 700;
}

@media only screen and (width <= 700px) {
  .contForm button {
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 14px;
  }
}

.politics {
  color: #fff;
  width: 100%;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 130%;
}

.politics span {
  opacity: .7;
}

.politics a {
  color: #fff;
  font-weight: 400;
}

.form {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 770px;
  display: flex;
}

@media only screen and (width <= 1100px) {
  .form {
    width: 100%;
  }
}

.form input {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 2px;
  width: 47%;
  margin-bottom: 20px;
  padding: 20px;
  font-family: Commissioner, sans-serif;
  font-size: 20px;
  font-weight: 300;
}

@media only screen and (width <= 700px) {
  .form input {
    padding: 10px;
    font-size: 16px;
  }
}

.form input::-moz-placeholder {
  color: #fff;
  opacity: .6;
  font-size: 20px;
  font-weight: 300;
  line-height: 130%;
}

.form input::placeholder {
  color: #fff;
  opacity: .6;
  font-size: 20px;
  font-weight: 300;
  line-height: 130%;
}

@media only screen and (width <= 700px) {
  .form input::-moz-placeholder {
    font-size: 14px;
  }

  .form input::placeholder {
    font-size: 14px;
  }
}

.form textarea {
  resize: none;
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 2px;
  width: 100%;
  height: 205px;
  margin-bottom: 25px;
  padding: 20px;
  font-family: Commissioner, sans-serif;
  font-size: 20px;
  font-weight: 300;
}

@media only screen and (width <= 700px) {
  .form textarea {
    height: 100px;
    padding: 10px;
    font-size: 16px;
  }
}

.form textarea::-moz-placeholder {
  color: #fff;
  opacity: .6;
  font-size: 20px;
  font-weight: 300;
  line-height: 130%;
}

.form textarea::placeholder {
  color: #fff;
  opacity: .6;
  font-size: 20px;
  font-weight: 300;
  line-height: 130%;
}

@media only screen and (width <= 700px) {
  .form textarea::-moz-placeholder {
    font-size: 14px;
  }

  .form textarea::placeholder {
    font-size: 14px;
  }
}

.formInfo {
  width: 500px;
}

@media only screen and (width <= 1100px) {
  .formInfo {
    width: 100%;
    display: flex;
  }
}

@media only screen and (width <= 1100px) and (width <= 450px) {
  .formInfo {
    justify-content: space-between;
  }
}

.formInfoItem {
  margin-bottom: 37px;
  margin-right: 40px;
}

@media only screen and (width <= 450px) {
  .formInfoItem {
    margin-right: 10px;
  }
}

.formInfoItem p, .formInfoItem a {
  color: #fff;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 300;
}

@media only screen and (width <= 700px) {
  .formInfoItem p, .formInfoItem a {
    font-size: 16px;
  }
}

.formInfoItem a {
  text-decoration: none;
}

.formInfoItem p:nth-of-type(2) {
  opacity: .6;
}

.formSection {
  background-color: #4c64e7;
  padding: 100px 0;
}

@media only screen and (width <= 998px) {
  .formSection {
    padding: 20px 0;
  }
}

.product1Title {
  color: #fff;
  margin-bottom: 38px;
  font-size: 46px;
  font-weight: 700;
}

@media only screen and (width <= 700px) {
  .product1Title {
    font-size: 28px;
  }
}

.wrap {
  max-width: 1400px;
  margin: 0 auto;
}

@media only screen and (width <= 1500px) {
  .wrap {
    max-width: 1000px;
  }
}

@media only screen and (width <= 1100px) {
  .wrap {
    width: 100%;
    padding: 10px;
  }
}

footer {
  background-color: #242424;
  justify-content: space-between;
  align-items: flex-end;
  padding: 50px 100px;
  display: flex;
}

@media only screen and (width <= 900px) {
  footer {
    flex-wrap: wrap;
    padding: 30px 40px;
  }
}

.footerLogo {
  flex-direction: column;
  display: flex;
}

@media only screen and (width <= 900px) {
  .footerLogo {
    width: 100%;
    margin-bottom: 30px;
  }
}

.footerLogo img {
  margin-bottom: 10px;
}

@media only screen and (width <= 900px) {
  .footerLogo img {
    width: 250px;
  }
}

.footerLogo a {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  line-height: 130%;
  text-decoration: none;
}

.footerAddress {
  text-align: right;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 130%;
}

@media only screen and (width <= 900px) {
  .footerAddress {
    text-align: left;
    width: 100%;
  }
}

.menuMobile {
  z-index: 2;
  background: radial-gradient(76.44% 76.44% at 29.58% 31.61%, #00b2ff 0%, #794aff 68.23%, #44259a 100%);
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.menuMobile .close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.menuMobile nav {
  flex-direction: column;
  padding-top: 30px;
  display: flex;
}

.menuMobile nav a {
  color: #fff;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.helpEmail {
  background-color: #fff;
  width: 100%;
}

.helpEmailWrap {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.helpEmailText {
  padding-left: 160px;
}

@media only screen and (width <= 1200px) {
  .helpEmailText {
    padding-left: 10px;
  }
}

.helpEmailTitle {
  margin-bottom: 50px;
  font-size: 36px;
  font-weight: 600;
}

@media only screen and (width <= 700px) {
  .helpEmailTitle {
    margin-bottom: 15px;
    font-size: 18px;
  }
}

.helpEmailDesc {
  font-size: 22px;
  font-weight: 400;
}

@media only screen and (width <= 700px) {
  .helpEmailDesc {
    font-size: 12px;
  }
}

.helpEmailDesc a {
  color: #4c63e6;
  text-decoration: none;
}

.helpEmailImg {
  width: 400px;
  height: 600px;
  position: relative;
}

@media only screen and (width <= 1200px) {
  .helpEmailImg {
    width: 200px;
    height: 300px;
  }
}

@media only screen and (width <= 700px) {
  .helpEmailImg {
    width: 150px;
    height: 200px;
  }
}

.helpEmailImg img {
  position: absolute;
  top: -50px;
  left: 0;
}

@media only screen and (width <= 1200px) {
  .helpEmailImg img {
    width: 190px;
    top: -25px;
  }
}

@media only screen and (width <= 700px) {
  .helpEmailImg img {
    width: 140px;
  }
}
/*# sourceMappingURL=index.4e4bbe7d.css.map */
