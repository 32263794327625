@import support/reset
@import support/mixins
//@import support/fonts
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700&family=Inconsolata&display=swap')

body
    background-color: #fff
    width: 100%
    overflow-x: hidden
    font-family: 'Commissioner', sans-serif
    position: relative

.myBg
    position: absolute
    width: 100%
    height: 100%
    z-index: -1
    left: 0
    top: 0

    .bgHeader
        width: 100%
        height: 100vh
        background: radial-gradient(76.44% 76.44% at 29.58% 31.61%, #00B2FF 0%, #794AFF 68.23%, #44259A 100%)
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%)
        +r(998)
            height: 400px
            clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%)

    .bgPotential
        width: 100%
        height: 590px
        background-color: #fff

    .bgMyVideo
        width: 100%
        height: 120vh
        background: #F9FAFF
        clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 100%)
        +r(998)
            height: 700px
            clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%)

    .bgUse
        width: 100%
        height: 115vh
        background: #fff

    .bgVersion
        width: 100%
        height: 120vh
        background: #F9FAFF

    .lineBg
        width: 100%
        height: 100%
        display: flex
        position: absolute
        left: 0
        top: 0
        +r(998)
            display: none

        .line
            width: 1px
            height: 100%
            position: absolute
            //left: 10px
            top: 0
            background-color: #D9D9D9
            opacity: .3
        //margin-left: calc( 100% / 6 )
        .line:nth-child(1)
            left: 200px

        .line:nth-child(2)
            left: calc((100% - 400px) / 5 * 1 + 200px)

        .line:nth-child(3)
            left: calc((100% - 400px) / 5 * 2 + 200px)

        .line:nth-child(4)
            left: calc((100% - 400px) / 5 * 3 + 200px)

        .line:nth-child(5)
            left: calc((100% - 400px) / 5 * 4 + 200px)

        .line:nth-child(6)
            left: calc((100% - 200px))


header
    //background: #4C64E7
    //background-image: linear-gradient( to right, transparent calc( 100% / 7), #4C64E7 calc( 100% / 7 - 1px ), #4C64E7 calc( 100% / 7 + 1px ), transparent calc( 100% / 7 + 1px ), transparent calc( 100% / 7 * 2))
    //transform: translate3d(0, 0, 0)
    width: 100%
    min-height: 100vh
    position: relative
    padding-top: 80px
    +r(998)
        min-height: 400px
        padding-top: 40px

.navMenu
    position: absolute
    left: 0
    top: 0
    width: 100%
    padding: 35px 0 10px 10px
    display: flex
    +r(998)
        padding: 10px
        justify-content: space-between
        align-items: center

    .burger
        display: none
        +r(998)
            display: block

    img
        padding: 5px 50px 5px 100px
        +r(998)
            padding: 10px

    nav
        display: flex
        +r(998)
            display: none

        a
            display: block
            padding: 5px 20px
            color: #fff
            font-weight: 400
            font-size: 18px
            text-decoration: none

.mainBlock
    width: 100%
    display: flex

    .mainBlockTitle
        width: 60%
        display: flex
        flex-direction: column
        padding-left: 270px
        padding-top: 250px
        align-items: flex-start
        color: #fff
        +r(1500)
            padding-left: 20px
        +r(998)
            padding-left: 20px
            padding-top: 100px

        .headerTitle
            font-size: 56px
            font-weight: 700
            margin-bottom: 30px
            +r(998)
                font-size: 18px

        .headerDesc
            font-size: 22px
            line-height: 33px
            font-weight: 300
            margin-bottom: 20px
            +r(998)
                font-size: 12px
                line-height: 120%
        .headerReestr
            font-size: 22px
            line-height: 33px
            font-weight: 300
            margin-bottom: 30px

        .headerButton
            font-size: 24px
            line-height: 30px
            font-weight: 600
            background-color: #4C63E6
            border: 1px solid #ffffff
            border-radius: 18px
            padding: 20px 40px
            color: #fff
            +r(998)
                //font-size: 18px
                padding: 10px 15px
                font-size: 14px

    .mainBlockImage
        width: 40%
        display: flex
        justify-content: flex-end
        padding-top: 30px
        +r(1500)
            overflow-x: hidden
            padding-top: 100px
            position: relative
        +r(998)
            display: block

        img
            max-width: 100%
            +r(1500)
                width: 400px
                height: 300px
            +r(998)
                width: auto
                max-width: none


.potential
    width: 100%
    display: flex
    justify-content: center

.potentialWrap
    width: 1400px
    +r(1500)
        padding: 20px
        width: 100%

    .potentialTitle
        font-weight: 600
        margin: 25px 0
        font-size: 36px
        line-height: 44px
        +r(998)
            font-size: 18px
            margin: 10px 0
            line-height: 100%

    .potentialDesc
        font-size: 22px
        line-height: 150%
        color: #828282
        margin-bottom: 70px
        +r(998)
            font-size: 12px
            margin-bottom: 30px

.potentialItems
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    padding: 0 10px

    .item
        width: 43%
        display: flex
        margin-bottom: 50px
        align-items: center
        +r(800)
            width: 100%
            margin-bottom: 20px

        img
            margin-right: 25px
            +r(800)
                width: 30px
                margin-right: 15px

    .itemName
        font-weight: 300
        font-size: 22px
        line-height: 27px
        color: #000000
        +r(998)
            font-size: 14px
            +r(800)
                line-height: 120%

.myVideo
    min-height: 100vh
    width: 100%
    display: flex
    justify-content: center
    padding-top: 150px
    +r(998)
        min-height: 300px
        padding-top: 50px

.myVideoWrap
    width: 1120px
    +r(1200)
        width: 100%
        padding: 0

iframe
    +r(1200)
        width: 100%
        height: 300px

.use
    width: 100%
    display: flex
    justify-content: center
    min-height: 80vh
    padding-bottom: 40px

.useWrap
    width: 1400px
    +r(1500)
        width: 998px
//padding: 0 20px
.useTitle
    font-weight: 600
    font-size: 36px
    line-height: 44px
    color: #000000
    margin: 80px 0 70px
    width: 100%
    +r(1500)
        padding: 0 20px
    +r(700)
        font-size: 22px
        margin: 40px 0 15px

.useBlock
    width: 1200px
    display: flex
    margin: 0 auto
    //border: 1px solid #333
    flex-wrap: wrap
    +r(1500)
        width: 100%

.useItem
    height: 315px
    font-weight: 300
    font-size: 22px
    line-height: 27px
    color: #000000
    position: relative
    border: 1px solid #D9D9D9
    +r(998)
        font-size: 14px
        height: 220px
        line-height: 15px

    +r(500)
        font-size: 10px
        height: 150px

    p
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        padding: 30px
        +r(700)
            padding: 10px

.useItem:nth-child(1)
    width: 440px
    background-image: url('../image/block1.png')
    background-size: cover
    +r(1500)
        width: 60%
        order: 1

.useItem:nth-child(2)
    width: 320px
    background-image: url('../image/image 5.png')
    background-size: cover
    border: none
    border-top: 1px solid #D9D9D9
    border-bottom: 1px solid #D9D9D9
    +r(1500)
        width: 40%
        border: 1px solid #D9D9D9
        border-left: none
        order: 1

.useItem:nth-child(3)
    width: 440px
    background-image: url('../image/block2.png')
    background-size: cover
    color: #fff

    p
        background: radial-gradient(156.35% 164.31% at 2.15% -7.72%, #00B2FF 0%, #794AFF 68.23%, #44259A 100%)

    +r(1500)
        width: 55%
        border: 1px solid #D9D9D9
        border-top: none
        border-left: none

        order: 3

.useItem:nth-child(4)
    width: 300px
    background-image: url('../image/block3.png')
    background-size: cover
    border-top: none
    +r(1500)
        width: 45%
        border: 1px solid #D9D9D9
        border-top: none
        order: 2

.useItem:nth-child(5)
    width: 300px
    background: radial-gradient(124.24% 120.35% at 0% -2.56%, #00B2FF 0%, #794AFF 68.23%, #44259A 100%)
    color: #fff
    border: none
    border-bottom: 1px solid #D9D9D9
    +r(1500)
        width: 30%
        border: 1px solid #D9D9D9
        border-top: none
        order: 3

.useItem:nth-child(6)
    width: 600px
    border-top: none
    background-image: url('../image/block4.png')
    background-size: cover
    color: #fff
    +r(1500)
        width: 70%
        border: 1px solid #D9D9D9
        border-top: none
        border-left: none
        order: 3

.useSliderItem
    padding: 10px

.version
    display: flex
    justify-content: center
    min-height: 80vh

.versionWrap
    width: 1200px
    display: flex
    flex-direction: column
    padding-bottom: 120px
    +r(998)
        padding-bottom: 40px

.versionTitle
    font-weight: 600
    font-size: 36px
    line-height: 44px
    width: 100%
    margin: 170px 0 70px
    text-align: center
    +r(700)
        font-size: 24px
        margin: 50px 0 30px

.versionItems
    width: 100%
    display: flex
    justify-content: center
    +r(998)
        flex-wrap: wrap

.versionItem
    background: #FFFFFF
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1)
    border-radius: 10px
    padding: 40px
    width: 420px
    height: 520px
    margin: 30px
    display: flex
    flex-direction: column
    +r(998)
        width: 100%
    +r(700)
        padding: 15px
        height: 370px

.versionItem:nth-child(2),.versionItem:nth-child(3)
    border: 2px solid #4C63E6

.versionItemTitle
    font-weight: 600
    font-size: 26px
    margin-bottom: 20px
    line-height: 100%
    +r(700)
        font-size: 18px

.versionItemDesc
    font-weight: 300
    font-size: 22px
    line-height: 27px
    color: #000000
    flex-grow: 2

    +r(700)
        font-size: 16px

    p
        padding: 4px 0

    .buyVersion
        color: #4C63E6

.versionItemButton
    margin-top: 20px
    p
        font-size: 14px
        padding: 10px
        color: #9d9d9d
    button
        background-color: transparent
        font-weight: 600
        font-size: 24px
        line-height: 29px
        padding: 13px 40px
        border: 1px solid #000000
        box-sizing: border-box
        border-radius: 18px
        +r(700)
            font-size: 18px

    .buttonBuy
        color: #fff
        background: linear-gradient(104.44deg, #4C63E6 -6.58%, #A24CE6 113.74%)
        border: 1px solid #4C63E6

.contForm
    display: flex
    justify-content: space-between
    +r(1100)
        flex-wrap: wrap

    button
        background-color: transparent
        color: #fff
        border: 1px solid #FFFFFF
        border-radius: 18px
        font-size: 24px
        font-weight: 700
        padding: 20px 36px
        +r(700)
            padding: 10px 15px
            font-size: 14px
            border-radius: 8px

.politics
    width: 100%
    font-size: 14px
    line-height: 130%
    color: #fff
    margin-bottom: 30px

    span
        opacity: .7

    a
        color: #ffffff
        font-weight: 400

.form
    width: 770px
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    +r(1100)
        width: 100%

    input
        width: 47%
        padding: 20px
        margin-bottom: 20px
        background-color: transparent
        border: 1px solid #ffffff
        border-radius: 2px
        font-weight: 300
        font-size: 20px
        font-family: "Commissioner", sans-serif
        color: #fff
        +r(700)
            padding: 10px
            font-size: 16px

        &::placeholder
            font-weight: 300
            font-size: 20px
            line-height: 130%
            color: #FFFFFF
            opacity: 0.6
            +r(700)
                font-size: 14px

    textarea
        width: 100%
        resize: none
        height: 205px
        padding: 20px
        margin-bottom: 25px
        background-color: transparent
        border: 1px solid #ffffff
        border-radius: 2px
        font-weight: 300
        font-size: 20px
        font-family: "Commissioner", sans-serif
        color: #fff
        +r(700)
            padding: 10px
            font-size: 16px
            height: 100px

        &::placeholder
            font-weight: 300
            font-size: 20px
            line-height: 130%
            color: #FFFFFF
            opacity: 0.6
            +r(700)
                font-size: 14px

.formInfo
    width: 500px
    +r(1100)
        width: 100%
        display: flex
        +r(450)
            justify-content: space-between

.formInfoItem
    margin-bottom: 37px
    margin-right: 40px
    +r(450)
        margin-right: 10px

    p, a
        color: #fff
        font-size: 22px
        font-weight: 300
        margin-bottom: 10px
        +r(700)
            font-size: 16px

    a
        text-decoration: none

    p:nth-of-type(2)
        opacity: .6

.formSection
    background-color: #4C64E7
    padding: 100px 0
    +r(998)
        padding: 20px 0

.product1Title
    color: #fff
    font-weight: 700
    font-size: 46px
    margin-bottom: 38px
    +r(700)
        font-size: 28px

.wrap
    max-width: 1400px
    margin: 0 auto
    +r(1500)
        max-width: 1000px
    +r(1100)
        width: 100%
        padding: 10px

footer
    padding: 50px 100px
    display: flex
    justify-content: space-between
    align-items: flex-end
    background-color: #242424
    +r(900)
        padding: 30px 40px
        flex-wrap: wrap

.footerLogo
    display: flex
    flex-direction: column
    +r(900)
        width: 100%
        margin-bottom: 30px

    img
        margin-bottom: 10px
        +r(900)
            width: 250px

    a
        font-weight: normal
        font-size: 16px
        line-height: 130%
        text-decoration: none
        color: #fff

.footerAddress
    font-weight: 300
    font-size: 16px
    line-height: 130%
    text-align: right
    color: #FFFFFF
    +r(900)
        width: 100%
        text-align: left

.menuMobile
    position: fixed
    display: none
    left: 0
    top: 0
    width: 100vw
    height: 100vh
    background: radial-gradient(76.44% 76.44% at 29.58% 31.61%, #00B2FF 0%, #794AFF 68.23%, #44259A 100%)
    z-index: 2
    padding: 20px

    .close
        position: absolute
        right: 10px
        top: 10px
        cursor: pointer

    nav
        padding-top: 30px
        display: flex
        flex-direction: column

        a
            display: block
            padding: 5px 20px
            color: #fff
            font-weight: 400
            font-size: 18px
            text-decoration: none

.helpEmail
    width: 100%
    background-color: #fff
.helpEmailWrap
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
.helpEmailText
    padding-left: 160px
    +r(1200)
        padding-left: 10px
.helpEmailTitle
    font-size: 36px
    font-weight: 600
    margin-bottom: 50px
    +r(700)
        font-size: 18px
        margin-bottom: 15px
.helpEmailDesc
    font-size: 22px
    font-weight: 400
    +r(700)
        font-size: 12px
    a
        color: #4C63E6
        text-decoration: none
.helpEmailImg
    width: 400px
    height: 600px
    position: relative
    +r(1200)
        width: 200px
        height: 300px
    +r(700)
        width: 150px
        height: 200px
    img
        position: absolute
        left: 0
        top: -50px
        +r(1200)
            width: 190px
            top: -25px
        +r(700)
            width: 140px

