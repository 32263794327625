// width
=r($width)
  @media only screen and (max-width: $width+ "px")
    @content

=rmin($width)
  @media only screen and (min-width: $width+ "px")
    @content

// height
=rh($height)
  @media only screen and (max-height: $height + "px")
    @content

=rhmin($height)
  @media only screen and (min-height: $height + "px")
    @content
